.font-selector {
    position: relative;
}
.font-selector-dropdown {
    list-style: none;
    margin: 0;
    max-height: 150px;
    overflow: scroll;
    padding: 0;
    position: absolute;
    width: 100%;
    z-index: 9999;
}
.font-selector-dropdown li {
    padding-left: 12px;
}
.font-selector-dropdown li:hover {
    background: #3875d7;
    color: #fff;
    cursor: pointer;
}