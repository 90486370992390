.torden-sab-preview-container {
    background-color: white;
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
    line-height: 1.5em;
    margin-bottom: 2.0rem;
    padding: 10px;
    position: fixed;
    width: 100%;
    z-index: 9999999;
}
.torden-sab-preview-text {
    font-size: 12px;
    margin-bottom: 5px;
}

/* The CSS classes below are used on frontend */
.torden-sab-container {
    min-height: 47px;
}
.torden-sab-bar-container {
    align-items: center;
    background-color: #fff;
    display: flex;
    min-height: 47px;
    justify-content: center;
    left: 0;
    text-align: center;
    opacity: 1!important;
    position: relative; /* Dinamically setted on main.js. DO NOT ADD TO main.js CSS. */
    width: 100%;
    z-index: 9999; /* This has to be 9999999999 on main.js */
}
.torden-sab-full-link {
    background-color: transparent;
    height: 100%;
    position: absolute;
    width: 100%;
  }
.torden-sab-background {
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: -1;
}
.torden-sab-text {
    color: rgb(250, 212, 62);
    padding: 9px 20px;
}
.torden-sab-button {
    background-color: rgb(250, 212, 62);
    border-radius: 3px;
    color: #000;
    padding: 3px 10px;
    margin-right: 35px;
    min-width: fit-content;
    text-align: center;
    font-family: "Work Sans","HelveticaNeue","Helvetica Neue",sans-serif;
    text-decoration: none !important;
    cursor: pointer;
    font-size: 13px;
    vertical-align: middle;
    touch-action: manipulation;
}
.torden-sab-button:hover {
    opacity: 0.7;
}
.torden-sab-close-button {
    color: #fff;
    cursor: pointer;
    font-size: 18px;
    position: absolute;
    right: 10px;
    z-index: 99999;
}